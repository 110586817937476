import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { MdTrendingUp } from "react-icons/md";
import {
  BsArrowsAngleContract,
  BsArrowsAngleExpand,
  BsFillFileLockFill,
} from "react-icons/bs";
import { FcCurrencyExchange } from "react-icons/fc";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineCancel } from "react-icons/md";

import classes from "./DashNav.module.css";

const DashNav = ({ dashboard }) => {
  const navRef = useRef();
  const [navBtnState, setNavBtnState] = useState(false);

  const navHander = () => {
    navRef.current.classList.toggle(classes.open);
    navRef.current.classList.toggle(classes.close);
    setNavBtnState((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNavBtnState(false);
      navRef.current.classList.remove(classes.open);
      navRef.current.classList.add(classes.close);
    }
  };

  useEffect(() => {
    const dashRef = dashboard.current;
    if (navBtnState) {
      dashRef.addEventListener("click", handleClickOutside);
    } else {
      dashRef.removeEventListener("click", handleClickOutside);
    }

    return () => {
      dashRef.removeEventListener("click", handleClickOutside);
    };
  }, [navBtnState, dashboard]);

  return (
    <nav className={`${classes.nav} ${classes.close}`} ref={navRef}>
      <ul>
        <li className={classes.navItem}>
          <MdTrendingUp className={classes.icon} />
          <NavLink
            to="/dashboard/subscriptions"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Portfolio
          </NavLink>
        </li>
        <li className={classes.navItem}>
          <BsArrowsAngleContract className={classes.icon} />
          <NavLink
            to="/dashboard/deposits"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Deposits
          </NavLink>
        </li>
        <li className={classes.navItem}>
          <BsArrowsAngleExpand className={classes.icon} />
          <NavLink
            to="/dashboard/withdrawls"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Withdrawals
          </NavLink>
        </li>
        <li className={classes.navItem}>
          <BsFillFileLockFill className={classes.icon} />
          <NavLink
            to="/dashboard/password"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Password
          </NavLink>
        </li>
        <li className={classes.navItem}>
          <FcCurrencyExchange className={classes.icon} />
          <NavLink
            to="/dashboard/bonus"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Bonus
          </NavLink>
        </li>
        {navBtnState ? (
          <MdOutlineCancel className={classes.menu} onClick={navHander} />
        ) : (
          <RxHamburgerMenu className={classes.menu} onClick={navHander} />
        )}
      </ul>
    </nav>
  );
};

export default DashNav;
