import React, { useRef, Fragment, useState } from "react";
import emailjs from "@emailjs/browser";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { HiAtSymbol, HiMail } from "react-icons/hi";
import { MdPermIdentity } from "react-icons/md";
import useInput from "../../hooks/userInput";

import classes from "./Contact.module.css";
import { alertActions } from "../../store/alert-slice";
import Spinner from "../UI/Spinner";
import { company } from "../../config/config";

const Contact = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const form = useRef();
  const btnRef = useRef();

  const {
    value: emailInput,
    enteredValueIsValid: emailInputIsValid,
    hasError: emailInputIsInvalid,
    valueInputChangedHandler: emailInputChangedHandler,
    valueInputBlurHandler: emailInputBlurHandler,
    reset: emailInputReset,
  } = useInput((value) => value.trim().includes("@"));

  const {
    value: nameInput,
    enteredValueIsValid: nameInputIsValid,
    hasError: nameInputIsInvalid,
    valueInputChangedHandler: nameInputChangedHandler,
    valueInputBlurHandler: nameInputBlurHandler,
    reset: nameInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: msgInput,
    enteredValueIsValid: msgInputIsValid,
    hasError: msgInputIsInvalid,
    valueInputChangedHandler: msgInputChangedHandler,
    valueInputBlurHandler: msgInputBlurHandler,
    reset: msgInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;

  if (emailInputIsValid && nameInputIsValid && msgInputIsValid) {
    formIsValid = true;
  }

  const emailInputClasses = emailInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const nameInputClasses = nameInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const msgInputClasses = msgInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const sendEmail = (e) => {
    e.preventDefault();
    btnRef.current.classList.add(classes.scaleIn);
    setShowSpinner(true);

    emailjs
      .sendForm(
        "service_l7qsbpf",
        "template_rak6zul",
        form.current,
        "-7jES8GS9JXjPaO7Y"
      )
      .then(
        (result) => {
          dispatch(
            alertActions.setState({
              message:
                "Email sent succssfully! We will get back to you as soon as possible.",
              status: "success",
            })
          );
          e.target.reset();
          setShowSpinner(false);
          setTimeout(() => {
            btnRef.current.classList.remove(classes.scaleIn);
          }, 100);
        },
        (error) => {
          dispatch(
            alertActions.setState({
              message:
                "There was an error sending your email, please try again later or copy our email and email us direct from your mailbox",
              status: "error",
            })
          );
          setShowSpinner(false);
          setTimeout(() => {
            btnRef.current.classList.remove(classes.scaleIn);
          }, 100);
        }
      );
    nameInputReset();
    emailInputReset();
    msgInputReset();
  };

  return (
    <Fragment>
      <Helmet>
        <title>Contact {company}</title>
        <meta name="description" content="We offer 24/7 customer support" />
        <link rel="canonical" href="/contact-us" />
      </Helmet>
      <div className={classes.address}>
        <h2>{company}</h2>
        <address>11 Wall St, New York, NY 10005, United States</address>
        <p>Email → service@orohandel.com</p>
        {/* <p>Tel → +1-929-645-1958</p> */}
      </div>
      <form className={classes.form} ref={form} onSubmit={sendEmail}>
        {showSpinner && <Spinner />}
        <div className={nameInputClasses}>
          <label htmlFor="name">
            <MdPermIdentity className={classes.icon} />
          </label>
          <input
            type="text"
            name="name"
            className={classes.input}
            value={nameInput}
            onChange={nameInputChangedHandler}
            onBlur={nameInputBlurHandler}
            placeholder="Name"
          />
          {nameInputIsInvalid && <span>Please enter your name</span>}
        </div>
        <div className={emailInputClasses}>
          <label htmlFor="email">
            <HiAtSymbol className={classes.icon} />
          </label>
          <input
            type="email"
            name="email"
            className={classes.input}
            value={emailInput}
            onChange={emailInputChangedHandler}
            onBlur={emailInputBlurHandler}
            placeholder="you@example.com"
          />
          {emailInputIsInvalid && (
            <span>Please enter your valid email address</span>
          )}
        </div>
        <div className={msgInputClasses}>
          <label style={{ alignSelf: "flex-start" }}>
            <HiMail className={classes.icon} />
          </label>
          <textarea
            name="message"
            className={classes.textarea}
            value={msgInput}
            onChange={msgInputChangedHandler}
            onBlur={msgInputBlurHandler}
            placeholder="Message"
          />
          {msgInputIsInvalid && <span>This field cannot be empty</span>}
        </div>
        <div className={classes.action}>
          <button
            type="submit"
            ref={btnRef}
            value="Send"
            disabled={!formIsValid}
          >
            Send email
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default Contact;
