import classes from "./Withdrawal.module.css";
import { MdOutlineAttachMoney } from "react-icons/md";
import { FaBtc, FaRegFlag, FaRegCalendar } from "react-icons/fa";

import { formatAmount, formatDate } from "../../config/config";

const Withdrawal = (props) => {
  return (
    <div className={classes.content}>
      <div className={classes.outContainer}>
        <div className={classes.innerContainer}>
          <MdOutlineAttachMoney className={classes.icon} />
          <span className={classes.title}>Amount</span>
        </div>
        <span className={classes.value}>{formatAmount(props.amount)}</span>
      </div>
      <div className={classes.outContainer}>
        <div className={classes.innerContainer}>
          <FaBtc className={classes.icon} />
          <span className={classes.title}>Wallet</span>
        </div>
        <span className={classes.value}>
          {props.wallet.slice(0, props.wallet.length / 2)}...
        </span>
      </div>
      <div className={classes.outContainer}>
        <div className={classes.innerContainer}>
          <FaRegFlag className={classes.icon} />
          <span className={classes.title}>Status</span>
        </div>
        <span className={classes.value}>{props.status}</span>
      </div>
      <div className={classes.outContainer}>
        <div className={classes.innerContainer}>
          <FaRegCalendar className={classes.icon} />
          <span className={classes.title}>Date</span>
        </div>
        <span className={classes.value}>{formatDate(props.time)}</span>
      </div>
    </div>
  );
};

export default Withdrawal;
