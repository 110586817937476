import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useRef } from "react";

import classes from "./Dashboard.module.css";
import DashNav from "./DashNav";
import { Fragment } from "react";

const Dashboard = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const name = useSelector((state) => state.auth.user.name);
  const firstname = name.split(" ")[0];
  const dashRef = useRef();
  return (
    <Fragment>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Welcome to your dashboard" />
        <link rel="canonical" href="/dashboard" />
      </Helmet>
      <h1 className={classes.welcome}>
        Welcome to your dashboard {firstname}!
      </h1>
      <section className={classes.dashboard} ref={dashRef}>
        {isLoggedIn && <DashNav dashboard={dashRef} />}
        <main className={classes.outlet}>{isLoggedIn && <Outlet />}</main>
      </section>
    </Fragment>
  );
};

export default Dashboard;
