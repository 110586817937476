import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
// import { useRef } from "react";
// import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import LoadSpinner from "./components/UI/LoadSpinner";
import AlertModal from "./components/UI/AlertModal";
import SubForm from "./components/body/SubForm";
import Deposit from "./components/body/DepositForm";

import UserLayout from "./components/pages/UserLayout";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/main/Contact";
import Why from "./components/main/Why";
import Plans from "./components/body/Plans";
import Login from "./components/main/Login";
import Signup from "./components/main/Signup";
import Dashboard from "./components/main/Dashboard";
import Subscriptions from "./components/main/Subcriptions";
import Withdrawals from "./components/transactions/Withdrawals";
import ErrorModal from "./components/UI/ErrorModal";
import Deposits from "./components/transactions/Deposits";
import Password from "./components/main/Password";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Bonus from "./components/main/Bonus";

import { loader as withdrawsLoader } from "./components/transactions/Withdrawals";
import { loader as depositsLoader } from "./components/transactions/Deposits";
import { loader as bonusLoader } from "./components/main/Bonus";
import ProtectedRoute from "./components/auth/ProtectedRoute";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<UserLayout />} errorElement={<ErrorModal />}>
      <Route index element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/why-us" element={<Why />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<Signup />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/resetPassword/:token" element={<ResetPassword />} />
      <Route path="/prePayment" element={<SubForm />} />
      <Route
        path="dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route index element={<Subscriptions />} />
        <Route path="/dashboard/subscriptions" element={<Subscriptions />} />
        <Route
          path="/dashboard/withdrawls"
          element={<Withdrawals />}
          loader={withdrawsLoader}
        />
        <Route
          path="/dashboard/deposits"
          element={<Deposits />}
          loader={depositsLoader}
        />
        <Route path="/dashboard/password" element={<Password />} />
        <Route
          path="/dashboard/bonus"
          element={<Bonus />}
          loader={bonusLoader}
        />
      </Route>
    </Route>
  )
);

function App() {
  const showSpinner = useSelector((state) => state.spinner.showSpinner);
  const showModal = useSelector((state) => state.alert.showModal);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // const showSlide = useSelector((state) => state.slide.showSlide);
  const depoSlide = useSelector((state) => state.slide.depoSlide);
  // const tawkMessengerRef = useRef();

  return (
    <Fragment>
      {showModal && <AlertModal />}
      {showSpinner && <LoadSpinner />}
      {/* {isLoggedIn && showSlide && <SubForm />} */}
      {isLoggedIn && depoSlide && <Deposit />}
      {/* <TawkMessengerReact
        propertyId="643012464247f20fefea5d26"
        widgetId="1gtdseqgd"
        ref={tawkMessengerRef}
      /> */}
      <RouterProvider router={router} />;
    </Fragment>
  );
}

export default App;
