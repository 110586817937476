import { MdOutlineAttachMoney } from "react-icons/md";
import { FaRegFlag, FaRegCalendar } from "react-icons/fa";

import { formatDate, formatAmount } from "../../config/config";
import classes from "./InFlow.module.css";

const InFlow = (props) => {
  return (
    <div className={classes.content}>
      <div className={classes.outContainer}>
        <div className={classes.innerContainer}>
          <MdOutlineAttachMoney className={classes.icon} />
          <span className={classes.title}>Amount</span>
        </div>
        <span className={classes.value}>{formatAmount(props.amount)}</span>
      </div>
      <div className={classes.outContainer}>
        <div className={classes.innerContainer}>
          <FaRegFlag className={classes.icon} />
          <span className={classes.title}>Status</span>
        </div>
        <span className={classes.value}>{props.status}</span>
      </div>
      <div className={classes.outContainer}>
        <div className={classes.innerContainer}>
          <FaRegCalendar className={classes.icon} />
          <span className={classes.title}>Date</span>
        </div>
        <span className={classes.value}>{formatDate(props.time)}</span>
      </div>
    </div>
  );
};

export default InFlow;
